import React, { useState, useEffect } from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const VehicleMake = ({ index, year, make }) => {
  const [makes, setMakes] = useState()
  const [loading, setLoading] = useState(true)
  const { handleChange } = useAutoContext()
  const url =
    "https://vehapi.com/api/v1/moto-lists/get/moto/makes/" + year + "/asc"
  // start
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("X-Requested-With", "XMLHttpRequest")
  myHeaders.append(
    "Authorization",
    "Bearer " + process.env.GATSBY_VEHICLE_API_TOKEN
  )
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }
  const getMake = async () => {
    try {
      const response = await fetch(url, requestOptions)
      const result = await response.json()

      setMakes(result)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getMake()
  }, [])
  // end

  return (
    <select
      name="make"
      id={index}
      value={make}
      onChange={handleChange}
      required
    >
      {/* placeholder */}
      <option className="placeholder" value="">
        Make
      </option>

      {/* makes */}
      {loading
        ? "...loading"
        : makes.map((item, index) => (
            <option key={index} value={item.make}>
              {item.make}
            </option>
          ))}
    </select>
  )
}

export default VehicleMake
