import React from "react"
import { MdRemove } from "react-icons/md"
import { useAutoContext } from "../../context/auto_context_copy"

const VehicleInput = ({ index, driver }) => {
  const { firstName, lastName, id } = driver
  const { drivers, handleChangeDriver, removeDriver } = useAutoContext()

  return (
    <div className="vehicle-container" id={id}>
      <div className="field-container">
        {/* First Name */}
        <div className="name-container">
          <label htmlFor="firstName" className="label">
            <span className="driver-name">First Name</span>
          </label>
          <input
            type="text"
            name="firstName"
            id={index}
            value={drivers[index].firstName}
            onChange={handleChangeDriver}
            required
          />
        </div>
        {/* Last Name */}
        <div className="name-container">
          <label htmlFor="lastName" className="label">
            <span className="driver-name">Last Name</span>
          </label>
          <input
            type="text"
            name="lastName"
            id={index}
            value={drivers[index].lastName}
            onChange={handleChangeDriver}
            required
          />
        </div>
      </div>

      {/* remove vehicle */}
      {index > 0 && (
        <div className="remove-container">
          <button
            className="remove"
            id="remove-vehicle"
            onClick={() => removeDriver(id)}
          >
            remove
            <MdRemove />
          </button>
        </div>
      )}
    </div>
  )
}

export default VehicleInput
