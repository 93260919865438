import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const UnderinsuredMotorist = () => {
  const { allCoverage } = useAutoContext()
  const { underinsuredMotorist } = allCoverage

  return (
    <>
      <h3>UnderinsuredMotorist:</h3>
      <p>
        {underinsuredMotorist === "25/65"
          ? "Your underinsured motorist bodily injury limits are currently set at 25/65. That means, in a covered accident, initiated by someone else who does not carry enough insurance to cover your medical bills, you would be covered up to $25,000 per person but it would not exceed $65,000 for the entire accident. We recommend our customers carry uninsured motorist bodily injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : underinsuredMotorist === "50/100"
          ? "Your underinsured motorist bodily injury limits are currently set at 50/100. That means, in a covered accident, initiated by someone else who does not have any insurance, you or one of your drivers, would be covered up to $50,000 per person but it would not exceed $100,000 for the entire accident. We recommend our customers carry uninsured motorist bodily injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : underinsuredMotorist === "100/300"
          ? "Your underinsured motorist bodily injury limits are currently set at 100/300. That means, in a covered accident, initiated by someone else who does not have any insurance, you or one of your drivers, would be covered up to $100,000 per person but it would not exceed $300,000 for the entire accident. We recommend our customers carry uninsured motorist bodily injury limits of at least 250/500. Please give us a call to discuss increasing your limits."
          : underinsuredMotorist === "250/500"
          ? "Your underinsured motorist bodily injury limits are currently set at 250/500. That means, in a covered accident, initiated by someone else who does not have any insurance, you or one of your drivers, would be covered up to $250,000 per person but it would not exceed $500,000 for the entire accident. We recommend our customers carry uninsured motorist bodily injury limits of at least 500/500. Please give us a call to discuss increasing your limits."
          : "You do not currently have underinsured motorist bodily injury limits on your policy. That means, in an accident, initiated by someone else who does not carry enough insurance to cover your medical bills, it would be your responsibility to pick up any medical bills incurred in the accident. We highly recommend you carry the same coverage that you do on your bodily injury limits. Please give us a call to discuss adding this coverage to your policy."}
      </p>
      <br />
    </>
  )
}

export default UnderinsuredMotorist
