import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const BodilyInjury = () => {
  const { allCoverage } = useAutoContext()
  const { bodilyInjury } = allCoverage

  return (
    <>
      <h3>Bodily Injury:</h3>
      <p>
        {bodilyInjury === "25/65"
          ? "Your bodily injury limits are currently set at 25/65. That means, in a covered accident initiated by you or one of your drivers, those individuals that you potentially hurt in an accident will be covered up to $25,000 per person but it will not exceed $65,000 for the entire accident. If medical expenses for the injured party exceed these limits, this may result in money out of your pocket. We recommend our customers carry bodily injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : bodilyInjury === "50/100"
          ? "Your bodily injury limits are currently set at 50/100. That means, in a covered accident initiated by you or one of your drivers, those individuals that you potentially hurt in an accident will be covered up to $50,000 per person but it will not exceed $100,000 for the entire accident. If medical expenses for the injured party exceed these limits, this may result in money out of your pocket. We recommend our customers carry bodily injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : bodilyInjury === "100/300"
          ? "Your bodily injury limits are currently set at 100/300. That means, in a covered accident initiated by you or one of your drivers, those individuals that you potentially hurt in an accident will be covered up to $100,000 per person but it will not exceed $300,000 for the entire accident. If medical expenses for the injured party exceed these limits, this may result in money out of your pocket. To protect your assets, we recommend increasing your limits to 250/500. Please give us a call to discuss increasing your limits."
          : "Your bodily injury limits are currently set at 250/500. That means, in a covered accident initiated by you or one of your drivers, those individuals that you potentially hurt in an accident will be covered up to $250,000 per person but it will not exceed $500,000 for the entire accident. If medical expenses for the injured party exceed these limits, this may result in money out of your pocket. We recommend our customers carry a personal umbrella policy, which would provide you with another $1 million in coverage to protect your assets. Please give us a call to discuss your personal umbrella policy."}
      </p>
      <br />
    </>
  )
}

export default BodilyInjury
