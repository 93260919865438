import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"
import BodilyInjury from "./BodilyInjury"
import GuestLiability from "./GuestLiability"
import MedicalPayments from "./MedicalPayments"
import PropertyDamage from "./PropertyDamage"
import TransportTrailer from "./TransportTrailer"
import TransportTrailerDeductible from "./TransportTrailerDeductible"
import UnderinsuredMotorist from "./UnderinsuredMotorist"
import UninsuredMotorist from "./UninsuredMotorist"

// Document Component
const MyDocument = () => {
  const { vehicles, allCoverage, data, allData, drivers } = useAutoContext()

  return (
    <>
      <div>
        <p>Dear [firstname],</p>
        <br />
        <p>
          Your motorcycle insurance policy is about to renew, and it is usually
          a good time to discuss any concerns that you may have about your
          policy
        </p>
        <br />
        <p>
          To ensure that you are aware of your coverages, we have put together
          the following review of your policy. If you have any questions or
          concerns, please give us a call at [agencyphone] or simply reply to
          this email.
        </p>
        <br />
        <h3>Coverage Explanations</h3>
        <br />
        <p>
          <strong>Vehicles on your Policy:</strong> Below is the YEAR, MAKE and
          MODEL of all vehicles listed on your policy. Please be aware, that you
          can only insure vehicles that are registered to the named insured(s)
          listed on the policy. You cannot insure a vehicle that is registered
          to someone else (example: your child, parent, grandparent, friend,
          etc.)
        </p>
        <br />
        <p>
          If one of the vehicles listed below is registered to someone other
          than the named insured(s), please give us a call and we can help find
          a solution.
        </p>
        <br />
        <p>
          Also, please note, we do not currently insure vehicles that have a
          salvaged, branded or rebuilt title. If one of the vehicles listed
          below does fall into one of those categories, please call us and we
          can help find a solution.
        </p>
        <br />

        {/* list of vehicles */}
        <div className="vehicle-list">
          <ul>
            {vehicles.map((item, index) => {
              return (
                <li key={index} className="vehicle-item">
                  {item.year} {item.make} {item.model}
                </li>
              )
            })}
          </ul>
        </div>
        <br />

        {/* Dynamic Data */}
        <BodilyInjury />
        <GuestLiability />
        <PropertyDamage />
        <UninsuredMotorist />
        <UnderinsuredMotorist />
        <MedicalPayments />

        {/* individual vehicle coverage */}
        {data.map((item, index) => {
          const { title, name } = item

          return (
            <div key={index}>
              {/* title */}
              <h3 className="coverage-title">{title}</h3>
              {/* description */}
              {/* <p>{item.fields.description}</p> */}
              <br />
              {/* vehicles */}
              <div className="vehicle-list">
                <ul>
                  {vehicles.map((vehicle, index) => {
                    return (
                      <li key={index} className="vehicle-coverage-item">
                        {vehicle.year} {vehicle.make} {vehicle.model}:{" "}
                        <strong>
                          {vehicle.coverage[name] || "not purchased"}
                        </strong>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <br />
            </div>
          )
        })}

        {/* Dynamic data */}
        <TransportTrailer />
        <TransportTrailerDeductible />

        {/* drivers */}
        <div>
          <h3 className="driver-title">Drivers on your Policy:</h3>
          <p>
            The following individuals are those persons who you have selected to
            drive your vehicles on a regular basis:
          </p>
          <br />
          <div className="driver-list">
            <ul>
              {drivers.map((driver, index) => {
                return (
                  <li key={index} className="driver-item">
                    {driver.firstName} {driver.lastName}
                  </li>
                )
              })}
            </ul>
          </div>
          <br />
          <p>
            *Please note, that if there are individuals who are living in your
            house, who have regular access to your vehicle(s) and are over the
            age of 16, they need to be added to your insurance policy as
            drivers.
          </p>
        </div>
        <br />
        <p>
          If you have any questions coverages listed above, please do not
          hesitate to reach out to us either by replying to this email or
          calling at [agencyphone].
        </p>
        <br />
        <p>
          Thank you for being a member of our agency, we recognize that there
          are plenty of other options out there. We strive to give you the best
          service we can. If there is anything, we can help you with, please let
          us know.
        </p>
        <br />
        <p>Best Regards,</p>
        <br />
        <br />
        <br />
        <p>[signature]</p>
      </div>
    </>
  )
}

export default MyDocument
