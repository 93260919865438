import React from "react"
import AllCoverages from "./AllCoverages"
import { Formik, Form } from "formik"
import { useAutoContext } from "../../context/auto_context_copy"

const FormThree = () => {
  const { allCoverage, handlePrevStep, handleNextCoverageStep } =
    useAutoContext()

  return (
    <Formik initialValues={allCoverage} onSubmit={handleNextCoverageStep}>
      {({ values }) => (
        <Form>
          <AllCoverages />

          <hr className="line" />

          {/* buttons */}
          <div className="btn-container">
            {/* back button */}
            <button
              type="button"
              className="btn back-btn"
              onClick={() => handlePrevStep(values)}
            >
              Back
            </button>
            {/* next button */}
            <button className="btn next-btn" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormThree
