import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const TransportTrailerDeductible = () => {
  const { allCoverage } = useAutoContext()
  const { transportTrailerDeductible } = allCoverage

  return (
    <>
      <h3>TransportTrailerDeductible :</h3>
      <p>
        {transportTrailerDeductible === "$100"
          ? "Your current deductible for the transportation trailer is $100."
          : transportTrailerDeductible === "$250"
          ? "Your current deductible for the transportation trailer is $250."
          : transportTrailerDeductible === "$500"
          ? "Your current deductible for the transportation trailer is $500."
          : transportTrailerDeductible === "$1,000"
          ? "Your current deductible for the transportation trailer is $1,000."
          : "You do not currently have coverage for a trailer that you use to transport your motorcycle(s)."}
      </p>
      <br />
    </>
  )
}

export default TransportTrailerDeductible
