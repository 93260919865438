import React, { useState, useContext, useEffect } from "react"
import Client from "../Contentful"

const AutoContext = React.createContext()

const AutoProvider = ({ children }) => {
  // State
  const [vehicles, setVehicles] = useState([
    {
      id: new Date().getTime().toString(),
      year: "",
      make: "",
      model: "",
      coverage: {
        collisionDeductible: "",
        collisionCustom: "",
        comprehensiveDeductible: "",
        transportationExpense: "",
        roadsideCoverage: "",
        optionalEquipment: "",
        deathIndemnity: "",
        disabilityIncome: "",
      },
    },
  ])
  const [drivers, setDrivers] = useState([
    { id: new Date().getTime().toString(), firstName: "", lastName: "" },
  ])
  const [allCoverage, setAllCoverage] = useState()
  const [data, setData] = useState()
  const [currentStep, setCurrentStep] = useState(0)

  // getData
  const getData = async () => {
    try {
      let response = await Client.getEntries({
        content_type: "reviewRapidly",
        order: "sys.createdAt",
      })
      let coverage = formatData(response.items).filter(
        item => item.category === "motorcycle"
      )
      let individual = coverage.filter(item => item.type === "individual")
      let all = coverage.filter(item => item.type === "all")

      setAllCoverage(all)
      setData(individual)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const formatData = items => {
    let temp = items.map(item => {
      let id = item.sys.id
      let output = { ...item.fields, id }
      return output
    })
    return temp
  }

  // Scroll to Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  // Vechicle Add/Remove

  // add vehicle
  const addVehicle = () => {
    let newVehicle = {
      id: new Date().getTime().toString(),
      year: "",
      make: "",
      model: "",
      coverage: {
        collisionDeductible: "",
        collisionCustom: "",
        comprehensiveDeductible: "",
        transportationExpense: "",
        roadsideCoverage: "",
        optionalEquipment: "",
        deathIndemnity: "",
        disabilityIncome: "",
      },
    }
    let newData = [...vehicles, newVehicle]
    setVehicles(newData)
  }
  // remove vehicle
  const removeVehicle = id => {
    let newData = vehicles.filter(vehicle => vehicle.id !== id)
    setVehicles(newData)
  }

  // Driver Add/Remove

  // add driver
  const addDriver = () => {
    let newDriver = {
      id: new Date().getTime().toString(),
      firstName: "",
      lastName: "",
    }
    let newData = [...drivers, newDriver]
    setDrivers(newData)
  }
  // remove driver
  const removeDriver = id => {
    let newData = drivers.filter(driver => driver.id !== id)
    setDrivers(newData)
  }

  // handleChange vehicles
  const handleChange = e => {
    let index = e.target.id
    let name = e.target.name
    let value = e.target.value
    let newData = vehicles

    newData[index][name] = value

    setVehicles([...newData])
  }
  // handleChange drivers
  const handleChangeDriver = e => {
    let index = e.target.id
    let name = e.target.name
    let value = e.target.value
    let newData = drivers

    newData[index][name] = value

    setDrivers([...newData])
  }

  const handleLogoClick = () => {
    window.location.reload()
    setCurrentStep(0)
  }

  // Form Steps

  // next step in form
  const handleNextStep = newData => {
    let newVehicleData = vehicles.map((vehicle, index) =>
      Object.assign(vehicle, newData[index])
    )

    setVehicles(newVehicleData)
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }
  // next step in form
  const handleNextCoverageStep = newData => {
    setAllCoverage(newData)
    setCurrentStep(prev => prev + 1)
    scrollToTop()
  }

  // previous step in form
  const handlePrevStep = newData => {
    // setVehicles(prev => [...prev, newData])
    setCurrentStep(prev => prev - 1)
    scrollToTop()
  }
  // form submit
  const handleSubmit = formData => {
    console.log(formData)
  }

  return (
    <AutoContext.Provider
      value={{
        vehicles,
        currentStep,
        handleNextStep,
        handlePrevStep,
        handleSubmit,
        addVehicle,
        removeVehicle,
        data,

        allCoverage,
        handleNextCoverageStep,

        handleChange,
        handleLogoClick,

        drivers,
        addDriver,
        removeDriver,
        handleChangeDriver,
      }}
    >
      {children}
    </AutoContext.Provider>
  )
}

export const useAutoContext = () => {
  return useContext(AutoContext)
}

export { AutoContext, AutoProvider }
