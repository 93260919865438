import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const PropertyDamage = () => {
  const { allCoverage } = useAutoContext()
  const { propertyDamage } = allCoverage

  return (
    <>
      <h3>Property Damage:</h3>
      <p>
        {propertyDamage === "$15,000"
          ? "Your property damage liability limit is set at $15,000. This coverage is if you or one of your driver's causes an accident to someone else’s property, the maximum that would be covered would be $15,000 of damage. If the damage exceeds this amount, it could result in money out of your own pocket. We recommend that our clients carry at least $300,000 in property damage liability. Please give us a call to discuss increasing your property damage liability."
          : propertyDamage === "$25,000"
          ? "Your property damage liability limit is set at $25,000. This coverage is if you or one of your driver's causes an accident to someone else’s property, the maximum that would be covered would be $25,000 of damage. If the damage exceeds this amount, it could result in money out of your own pocket. We recommend that our clients carry at least $300,000 in property damage liability. Please give us a call to discuss increasing your property damage liability."
          : propertyDamage === "$50,000"
          ? "Your property damage liability limit is set at $50,000. This coverage is if you or one of your driver's causes an accident to someone else’s property, the maximum that would be covered would be $50,000 of damage. If the damage exceeds this amount, it could result in money out of your own pocket. We recommend that our clients carry at least $300,000 in property damage liability. Please give us a call to discuss increasing your property damage liability."
          : "Your property damage liability limit is set at $100,000. This coverage is if you or one of your driver's causes an accident to someone else’s property, the maximum that would be covered would be $100,000 of damage. If the damage exceeds this amount, it could result in money out of your own pocket. We recommend that our clients carry at least $300,000 in property damage liability. Please give us a call to discuss increasing your property damage liability."}
      </p>
      <br />
    </>
  )
}

export default PropertyDamage
