import React from "react"
import Drivers from "./Drivers"
import { useAutoContext } from "../../context/auto_context_copy"

// VEHICLE FORM
const FormOne = () => {
  const { handleNextStep, handlePrevStep } = useAutoContext()

  return (
    <form className="form" onSubmit={handleNextStep}>
      <div className="form-center">
        <Drivers />
        <hr className="line" />

        <div className="btn-container">
          {/* back button */}
          <button
            type="button"
            className="btn back-btn"
            onClick={handlePrevStep}
          >
            Back
          </button>
          {/* next button */}
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
