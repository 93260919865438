import React from "react"
import Vehicles from "./Vehicles"
import { useAutoContext } from "../../context/auto_context_copy"

// VEHICLE FORM
const FormOne = () => {
  const { handleNextStep } = useAutoContext()

  return (
    <form className="form" onSubmit={handleNextStep}>
      <div className="form-center">
        <Vehicles />
        <hr className="line" />
        {/* next button */}
        <div className="btn-container">
          <button className="btn next-btn" type="submit">
            Next
          </button>
        </div>
      </div>
    </form>
  )
}

export default FormOne
