import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"
import AllDropdown from "./AllDropdown"

const Coverages = () => {
  const { allCoverage } = useAutoContext()

  return (
    <>
      <h2>Coverages</h2>

      {/* coverage input */}
      {allCoverage.map(item => {
        const { id, title, name, isRequired, selection } = item
        return (
          <AllDropdown
            title={title}
            name={name}
            key={id}
            id={id}
            // input={input}
            isRequired={isRequired}
            selection={[...selection]}
          />
        )
      })}
    </>
  )
}

export default Coverages
