import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const MedicalPayments = () => {
  const { allCoverage } = useAutoContext()
  const { medicalPayments } = allCoverage

  return (
    <>
      <h3>MedicalPayments:</h3>
      <p>
        {medicalPayments === "$1,000"
          ? "Medical payments provide for medical or funeral expenses that are incurred by you or your guests while using your vehicle(s) listed on the policy. Your current limit is set at $1,000. Please reach out to us if you would like to increase this coverage by either replying to this email or giving us a call."
          : medicalPayments === "$2,500"
          ? "Medical payments provide for medical or funeral expenses that are incurred by you or your guests while using your vehicle(s) listed on the policy. Your current limit is set at $2,500. Please reach out to us if you would like to increase this coverage by either replying to this email or giving us a call."
          : medicalPayments === "$5,000"
          ? "Medical payments provide for medical or funeral expenses that are incurred by you or your guests while using your vehicle(s) listed on the policy. Your current limit is set at $5,000. Please reach out to us if you would like to increase this coverage by either replying to this email or giving us a call."
          : "Medical payments provide for medical or funeral expenses that are incurred by you or your guests while using your vehicle(s) listed on the policy. Your current limit is set at $10,000. Please reach out to us if you would like to increase this coverage by either replying to this email or giving us a call."}
      </p>
      <br />
    </>
  )
}

export default MedicalPayments
