import React from "react"
import VehicleYear from "./VehicleYear"
import VehicleMake from "./VehicleMake"
import VehicleModel from "./VehicleModel"
import { MdRemove } from "react-icons/md"
import { useAutoContext } from "../../context/auto_context_copy"

const VehicleInput = ({ index, vehicle }) => {
  const { year, make, model, id } = vehicle
  const { removeVehicle } = useAutoContext()

  return (
    <div className="vehicle-container" id={id}>
      <div className="field-container">
        {/* vehicle year */}
        <VehicleYear id={id} index={index} year={year} />

        {/* vehicle make */}
        {year ? (
          <VehicleMake id={id} index={index} year={year} make={make} />
        ) : (
          // placeholder
          <select>
            <option>Make</option>
          </select>
        )}

        {/* vehicle model */}
        {year && make ? (
          <VehicleModel
            id={id}
            index={index}
            year={year}
            make={make}
            model={model}
          />
        ) : (
          // placeholder
          <select>
            <option>Model</option>
          </select>
        )}
      </div>

      {/* remove vehicle */}
      {index > 0 && (
        <div className="remove-container">
          <button
            className="remove"
            id="remove-vehicle"
            onClick={() => removeVehicle(id)}
          >
            remove
            <MdRemove />
          </button>
        </div>
      )}
    </div>
  )
}

export default VehicleInput
