import React from "react"
import Coverages from "./Coverages"
import { Formik, Form } from "formik"
import { useAutoContext } from "../../context/auto_context_copy"

const FormFour = () => {
  const { vehicles, handlePrevStep, handleNextStep } = useAutoContext()

  return (
    <Formik initialValues={vehicles} onSubmit={handleNextStep}>
      {({ values }) => (
        <Form>
          <Coverages />
          <hr className="line" />
          <div className="btn-container">
            {/* back button */}
            <button
              type="button"
              className="btn back-btn"
              onClick={() => handlePrevStep(values)}
            >
              Back
            </button>
            {/* next button */}
            <button className="btn next-btn" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormFour
