import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const GuestLiability = () => {
  const { allCoverage } = useAutoContext()
  const { guestLiability } = allCoverage

  return (
    <>
      <h3>GuestLiability:</h3>
      <p>
        {guestLiability === "25/65"
          ? "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 25/65. In a covered loss, your guest would be covered up to $25,000 per person but it would not exceed $65,000 for the entire accident. We recommend our customers carry guest liability injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : guestLiability === "50/100"
          ? "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 50/100. In a covered loss, your guest would be covered up to $50,000 per person but it would not exceed $100,000 for the entire accident. We recommend our customers carry guest liability injury limits of at least 100/300 or higher. Please give us a call to discuss increasing your limits."
          : guestLiability === "100/300"
          ? "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 100/300. In a covered loss, your guest would be covered up to $100,000 per person but it would not exceed $300,000 for the entire accident. We recommend our customers carry guest liability injury limits of at least 250/500 or higher. Please give us a call to discuss increasing your limits."
          : guestLiability === "250/500"
          ? "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 250/500. In a covered loss, your guest would be covered up to $250,000 per person but it would not exceed $500,000 for the entire accident. We recommend our customers carry a personal umbrella policy, which provides one million dollars of liability protection against potential claims. Please give us a call to discuss your personal umbrella policy options."
          : guestLiability === "500/500"
          ? "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 500/500. In a covered loss, your guest would be covered up to $500,000 per person but it would not exceed $500,000 for the entire accident. We recommend our customers carry a personal umbrella policy, which provides one million dollars of liability protection against potential claims. Please give us a call to discuss your personal umbrella policy options."
          : "The guest liability coverage provides protection to any guests that you are legally responsible for due to using your vehicle. Your current limits are currently set at 1000/1000. In a covered loss, your guest would be covered up to $1,000,000 per person but it would not exceed $1,000,000 for the entire accident. We recommend our customers carry a personal umbrella policy, which provides an additional one million dollars of liability protection against potential claims. Please give us a call to discuss your personal umbrella policy options."}
      </p>
      <br />
    </>
  )
}

export default GuestLiability
