import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"
import Dropdown from "./Dropdown"

const Coverages = () => {
  const { vehicles, data } = useAutoContext()

  return (
    <div className="coverages">
      {
        // vehicle coverages
        vehicles.map((vehicle, index) => {
          const { year, make, model } = vehicle

          return (
            <div className="coverage-item" key={index}>
              {/* vehicle year & make */}
              <h1 className="vehicle-title">{`${year} ${make}`}</h1>

              {/* vehicle model */}
              <h2 className="vehicle-subtitle">{`${model}`}</h2>

              {/* coverage input */}
              {data.map(item => {
                const { title, name, id, isRequired, selection } = item

                return (
                  <Dropdown
                    title={title}
                    name={name}
                    key={id}
                    id={id}
                    // type={input}
                    isRequired={isRequired}
                    selection={[...selection]}
                    index={index}
                  />
                )
              })}
            </div>
          )
        })
      }
    </div>
  )
}

export default Coverages
