import React from "react"
import VehicleInput from "./VehicleInput"
import { MdAdd } from "react-icons/md"
import { useAutoContext } from "../../context/auto_context_copy"

const Vehicles = () => {
  const { vehicles, addVehicle } = useAutoContext()

  return (
    <div className="row">
      <h2>Vehicles</h2>

      {/* vehicle input */}
      {vehicles.map((vehicle, index) => {
        return <VehicleInput key={index} index={index} vehicle={vehicle} />
      })}

      {/* add vehicle */}
      <div className="add-vehicle-container">
        <button
          className="add-vehicle"
          id="add-vehicle"
          type="button"
          onClick={addVehicle}
        >
          add vehicle
          <MdAdd />
        </button>
      </div>
    </div>
  )
}

export default Vehicles
