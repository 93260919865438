import React from "react"
import { useAutoContext } from "../../context/auto_context_copy"

const TransportTrailer = () => {
  const { allCoverage } = useAutoContext()
  const { transportTrailer } = allCoverage

  return (
    <>
      <h3>TransportTrailer :</h3>
      <p>
        {transportTrailer === "$600"
          ? "You currently have $600 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$800"
          ? "You currently have $800 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$1,050"
          ? "You currently have $1,500 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$1,300"
          ? "You currently have $1,300 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$1,600"
          ? "You currently have $1,600 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$1,900"
          ? "You currently have $1,900 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$2,400"
          ? "You currently have $2,400 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$3,000"
          ? "You currently have $3,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$4,000"
          ? "You currently have $4,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$5,000"
          ? "You currently have $5,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$6,000"
          ? "You currently have $6,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$7,000"
          ? "You currently have $7,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$8,000"
          ? "You currently have $8,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$9,000"
          ? "You currently have $9,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : transportTrailer === "$10,000"
          ? "You currently have $10,000 of coverage for a trailer while using it to transport your motorcycle(s). This does not extend coverage to the trailer while not transporting the vehicle(s) listed on your policy. If you would like to increase this coverage, please either reply to this email or give us a call."
          : "You have the option to add a transportation trailer to your motorcycle policy. This would cover a trailer you are using while transporting your motorcycle(s). You do not currently have this coverage. If you would like to add it, please either reply to this email or give us a call."}
      </p>
      <br />
    </>
  )
}

export default TransportTrailer
