import { Field } from "formik"
import React from "react"

const Dropdown = ({ title, name, id, selection, index, isRequired }) => {
  return (
    <div className="row">
      <label htmlFor="numvehicles" className="label">
        <h3 className="dropdown-title">{title}</h3>

        <Field
          name={`[${index}].coverage.${name}`}
          id={id}
          as="select"
          required={isRequired}
        >
          {selection.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            )
          })}
        </Field>
      </label>
    </div>
  )
}

export default Dropdown
